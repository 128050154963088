<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    scrollable
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <v-card>
      <v-card-title> Compartilhe o Psicoplanner! </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-img
              eager
              contain
              height="300"
              src="@/assets/images/boneca07.png"
            />
          </v-col>
          <v-col>
            <p style="word-break: break-word" class="text-center">
              Ajude o Psicoplanner a crescer ainda mais!<br />
              envie seu link de indicação para seus amigos<br />
              <!-- Para cada indicação sua que assinar o Psicoplanner,<br /> -->
              <!-- Você ganha 1 Mês Grátis! -->
            </p>

            <template v-if="hasLink">
              <div class="text-center">
                <h3>Compartilhe agora mesmo!</h3>
                <br />
                <v-btn
                  block
                  class="text-lowercase"
                  color="primary"
                  outlined
                  v-clipboard="company.recommendation_link"
                  v-clipboard:success="successCopy"
                >
                  {{ company.recommendation_link }}

                  <app-icon right>content_copy</app-icon>
                </v-btn>
              </div>
            </template>

            <v-btn
              v-if="!hasLink"
              @click="handleCreateRecommendationLink()"
              outlined
              block
              color="primary"
              :loading="loading"
            >
              Gerar Link de Indicação
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="dialog = false"> Voltar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,

      loading: false,
    };
  },

  computed: {
    company() {
      return this.$store.state.auth.company;
    },

    hasLink() {
      return this.company.recommendation_link != null;
    },
  },

  methods: {
    open() {
      this.dialog = true;
    },
    handleCreateRecommendationLink() {
      this.loading = true;
      this.$http
        .store("app/recommendation-link/generate")
        .then((response) => {
          this.$loading.finish();
          this.$auth.getLoginData();
          this.loading = false;
        })
        .catch((error) => {
          this.$loading.finish();
          this.loading = false;
        });
    },
    successCopy(action) {
      console.log(action);
      this.$snackbar({
        message: "Link copiado para a área de transferência",
        color: "success",
        timeout: 1000,
        showClose: false,
      });
    },
  },
};
</script>

<style>
</style>